const amplifyConfig = {
    Auth: {
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
      region: 'us-east-1',
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      oauth: {
        domain: process.env.REACT_APP_OAUTH_DOMAIN,
        scope: ['email'],
        redirectSignIn: process.env.REACT_APP_URL,
        redirectSignOut: process.env.REACT_APP_URL,
        responseType: 'code',
        options: {
          AdvancedSecurityDataCollectionFlag: false
        }
      },
      federationTarget: 'COGNITO_USER_POOL',
      identityProvider: 'AzureAD',
      config: {
        metadataUrl: 'https://login.microsoftonline.com/' + process.env.REACT_APP_AZURE_TENANT_ID + '/federationmetadata/2007-06/federationmetadata.xml?appid=' + process.env.REACT_APP_AZURE_APP_ID,
        serviceProviderName: 'urn:amazon:cognito:sp:' + process.env.REACT_APP_USER_POOL_ID,
        assertionConsumerServiceURL: process.env.REACT_APP_URL,
      },
    },
    API: {
      endpoints: [
        {
          name: process.env.REACT_APP_API_NAME, // name of the API in API Gateway console
          endpoint: process.env.REACT_APP_API_ENDPOINT,
          region: "us-east-1",
          paths: ['/']
        }
      ]
    }
  }

export default amplifyConfig;