import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DateRangeSelector.css';

function DateRangeSelector({ index, toggleReportVisibility }) {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  useEffect(() => {
    // Calculate the startDate two months ago from today
    const twoMonthsAgo = new Date();
    twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);

    setDateRange({
      startDate: twoMonthsAgo,
      endDate: new Date(),
    });
  }, []);

  const handleStartDateChange = (date, event) => {
    setDateRange({ ...dateRange, startDate: date });
    toggleReportVisibility(index, event, false, date, dateRange.endDate);
  };

  const handleEndDateChange = (date, event) => {
    setDateRange({ ...dateRange, endDate: date });
    toggleReportVisibility(index, event, false, dateRange.startDate, date);
  };

  return (
    <div className="date-range-selector">
      <div className="date-input">
        <label>Start Date:</label>
        <DatePicker
          selected={dateRange.startDate}
          onChange={(date, event) => handleStartDateChange(date, event)}
          selectsStart
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          minDate={new Date(new Date().getFullYear() - 1, 0, 1)} // One year ago
          maxDate={new Date()}
        />
      </div>
      <div className="date-input">
        <label>End Date:</label>
        <DatePicker
          selected={dateRange.endDate}
          onChange={(date, event) => handleEndDateChange(date, event)}
          selectsEnd
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          minDate={new Date(new Date().getFullYear() - 1, 0, 1)} // One year ago
          maxDate={new Date()}
        />
      </div>
    </div>
  );
}

export default DateRangeSelector;
