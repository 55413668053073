const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_AZURE_APP_ID,
      authority: 'https://login.microsoftonline.com/' + process.env.REACT_APP_AZURE_TENANT_ID,
      redirectUri: process.env.REACT_APP_URL,
      responseType: 'code',
      scopes: ['User.Read', 'openid', 'profile'],
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
    }
  };

export default msalConfig; 