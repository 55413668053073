import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ApiRequest from '../ApiRequest/ApiRequest';

const AuthenticatedUserView = (props) => {
  return (
    <div data-testid="AuthenticatedUserView">
      <ApiRequest email={props.email} accessToken={props.accessToken} region={props.selectedRegion} />
    </div>
  )
};

AuthenticatedUserView.propTypes = {
  email: PropTypes.string,
  accessToken: PropTypes.string,
  selectedRegion: PropTypes.string
};

AuthenticatedUserView.defaultProps = {};

export default AuthenticatedUserView;
