import React from 'react';
import RegionSelector from '../RegionSelector/RegionSelector';
import './Nav.css';

function Nav({ onRegionChange, accessToken, onSignOut }) {
    const handleSignOut = (e) => {
        onSignOut();
    };

    return (
        <div className="nav-bar">
            <img src="/images/logo.png" alt="Logo" className="logo" />
            <h1>Omni Internal Management Tools</h1>
            <RegionSelector onRegionChange={onRegionChange} />
            {accessToken && (
                <div className='sign-out-button'>
                    <button onClick={handleSignOut}>Sign Out</button>
                </div>
            )}
        </div>
    );
}

export default Nav;
