const authConfig = {
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: ['email'],
      redirectSignIn: process.env.REACT_APP_URL,
      redirectSignOut: process.env.REACT_APP_URL,
      responseType: 'code',
      options: {
        AdvancedSecurityDataCollectionFlag: false,
      },
    },
  }

export default authConfig;