import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import AuthenticatedUserView from '../AuthenticatedUserView/AuthenticatedUserView';
import { withAuthenticator } from '@aws-amplify/ui-react';

function AmplifySignIn({ selectedRegion }) {
    {/* This Amplify Login UI container is used for logging in with Cognito. For local DEV env development. */ }

    const [email, setEmail] = useState(null);
    const [accessToken, setAccessToken] = useState(null);

    const signIn = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            setEmail(user.attributes.email);

            const idToken = user.signInUserSession.idToken;
            setAccessToken(idToken.jwtToken);
        } catch (error) {
            console.log('Error signing in:', error);
        }
    };

    return (
        <div>
            {email && accessToken ? (
                <>
                    <AuthenticatedUserView email={email} accessToken={accessToken} selectedRegion={selectedRegion} />
                </>
            ) : (
                <div className='sign-in-button'>
                    <button onClick={signIn}>Sign In with Amplify</button>
                </div>
            )}
        </div>
    );
}

export default withAuthenticator(AmplifySignIn);
